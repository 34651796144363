export const NEW_GAME_SUCCESS = 'NEW_GAME_SUCCESS';
export const MAKE_MOVE = 'MAKE_MOVE';
export const WIN_GAME_SUCCESS = 'WIN_GAME_SUCCESS';
export const UPDATE_ON_CHANGE = 'UPDATE_ON_CHANGE';
export const GET_HIGH_SCORES_SUCCESS = 'GET_HIGH_SCORES_SUCCESS';
export const RESTART_BOARD = 'RESTART_BOARD';
export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';
export const CHANGE_THEME = 'CHANGE_THEME';
export const LOCK_BOARD = 'LOCK_BOARD';
export const UNLOCK_BOARD = 'UNLOCK_BOARD ';
